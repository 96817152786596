// eslint-disable-next-line
import { Carousel } from "@trendyol-js/react-carousel";
import ImageGallery from "react-image-gallery";
import falco from "./img/falco.png";
import flor from "./img/flors.JPG";
import wl8 from "./img/wl8.JPG";
import wlc from "./img/wlCirculo.JPG";
import home1 from "./img/home1.JPG";
import home2 from "./img/home2.png";
import home3 from "./img/home3.jpg";
import home4 from "./img/home4.JPG";

import logo1 from "./img/1_Logo_CEMEX.png";
import logo2 from "./img/2_Logo_AES-México_3.png";
import logo3 from "./img/3_Logo_WWF.png";
import logo4 from "./img/4_Logo-Pronatura-PY.png";
import logo5 from "./img/5_Logo_Pronatura-NE.png";
import logo6 from "./img/6_Logo_WildCoast.jpg";
import logo7 from "./img/7_Logo_AMMAC.png";
import logo8 from "./img/8_Logo_Ovis.png";
import logo9 from "./img/9_Logo_AnimalKarma.png";
import logo10 from "./img/10_Logo_PMC.jpeg";
import logo11 from "./img/11_Logo_FondoParaLaPaz.png";
import logo12 from "./img/12_Logo_Amigos_SianKa'an.png";
import logo13 from "./img/13_Logo_TlachtliCamp.jpg";
import logo14 from "./img/14_Logo_The_Santo_Grial.jpeg";
import logo15 from "./img/15_Logo_RáulCastroU.png";
import logo16 from "./img/16_Logo_RioVerde_Salvaje.jpg";
import logo17 from "./img/17_Logo_QueOndaRapaz.png";
import logo18 from "./img/18_Logo_IPN.png";
import logo19 from "./img/19_Logo_ColPos.png";
import logo20 from "./img/20_Logo_UAM.png";
import logo21 from "./img/21_Logo_UAEH.png";
import logo22 from "./img/22_Logo_UASLP.png";
import logo23 from "./img/23_Logo_UAAAN.png";
import logo24 from "./img/24_Logo_Cinvestav.png";
import logo25 from "./img/25_Logo_Balaan.jpg";
import logo26 from "./img/26_Logo_Calakmul.png";
import logo27 from "./img/27_Logo_SierraÁlvarez.jpg";
import logo28 from "./img/28_Logo_ElPotosí.png";
import logo29 from "./img/29_Logo_Sian_Kaan.jpg";
import logo30 from "./img/30_Logo_MilpillasForestal.jpg";
import logo31 from "./img/31_Logo_MilpillasSnBernarnido.jpg";
import logo32 from "./img/32_Logo_RENIECYT.png";
import logo33 from "./img/33_Logo_CONANP.png";
import logo34 from "./img/34_Logo_CONABIO.png";
import logo35 from "./img/35_Logo_PROFEPA_1.jpg";
import logo36 from "./img/36_Logo_SEMARNAT.png";

export const Highlight = ({ children, color }) => (
  <span
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: color,
      borderRadius: "2px",
      color: "#fff",
      padding: "90px 0",

      height: "200px",
      margin: "16px 16px 16px 0",
    }}
  >
    {" "}
    {children}{" "}
  </span>
);

const state = {
  showIndex: false,
  showBullets: true,
  infinite: true,
  showThumbnails: true,
  showFullscreenButton: true,
  showGalleryFullscreenButton: true,
  showPlayButton: true,
  showGalleryPlayButton: true,
  showNav: true,
  isRTL: false,
  slideDuration: 450,
  slideInterval: 2000,
  slideOnThumbnailOver: false,
  thumbnailPosition: "bottom",
  showVideo: {},
  useWindowKeyDown: true,
};

const imagesMain = [
  {
    original: `${logo1}`,

    originalWidth: 400,
  },
  {
    original: `${logo2}`,

    originalWidth: 400,
  },
  {
    original: `${logo3}`,

    originalWidth: 400,
  },
  {
    original: `${logo4}`,

    originalWidth: 400,
  },
  {
    original: `${logo5}`,

    originalWidth: 400,
  },
  {
    original: `${logo6}`,

    originalWidth: 400,
  },
  {
    original: `${logo7}`,

    originalWidth: 400,
  },
  {
    original: `${logo8}`,

    originalWidth: 400,
  },
  {
    original: `${logo9}`,

    originalWidth: 400,
  },
  {
    original: `${logo10}`,

    originalWidth: 400,
  },
  {
    original: `${logo11}`,

    originalWidth: 400,
  },
  {
    original: `${logo12}`,

    originalWidth: 400,
  },
  {
    original: `${logo13}`,

    originalWidth: 400,
  },
  {
    original: `${logo14}`,

    originalWidth: 400,
  },
  {
    original: `${logo15}`,

    originalWidth: 400,
  },
  {
    original: `${logo16}`,

    originalWidth: 400,
  },
  {
    original: `${logo17}`,

    originalWidth: 400,
  },
  {
    original: `${logo18}`,

    originalWidth: 400,
  },
  {
    original: `${logo19}`,

    originalWidth: 400,
  },
  {
    original: `${logo20}`,

    originalWidth: 400,
  },
  {
    original: `${logo21}`,

    originalWidth: 400,
  },
  {
    original: `${logo22}`,

    originalWidth: 400,
  },
  {
    original: `${logo23}`,

    originalWidth: 400,
  },
  {
    original: `${logo24}`,

    originalWidth: 400,
  },
  {
    original: `${logo25}`,

    originalWidth: 400,
  },
  {
    original: `${logo26}`,

    originalWidth: 400,
  },
  {
    original: `${logo27}`,

    originalWidth: 400,
  },
];
const Main = () => {
  return (
    <div className="container-main">
      <div className="columnas-banner">
        <img src={flor} alt="tucan" className="image-banner4" />
        <img src={wlc} alt="WLc" className="image-banner2" />
        <img src={falco} alt="aguila" className="image-banner3" />
        <img src={wl8} alt="WL8" className="image-banner1" />
      </div>
      <div className="titulo-mobile">
        <h1>Wildlife Management México</h1>
      </div>
      <div className="content-inicio">
        <p>
          En Wildlife Management Mexico, buscamos promover la conservación y el
          manejo de fauna silvestre, así como de los ecosistemas donde habitan,
          a través del aprovechamiento razonable de los recursos naturales y la
          educación ambiental.
        </p>
        <br></br>
        <br></br>
        <p>
          Para impulsar el manejo y conservación de la fauna silvestre
          participamos en diversos proyectos con diferentes actores, tanto con
          comunidades rurales, dependencias de gobierno, iniciativa privada e
          instituciones educativas en distintas partes de la República Mexicana
          y otros países.
        </p>
      </div>

      <div className="imgnos">
        <img src={home1} alt="WL1" width="400" height="400" />
        <img src={home2} alt="WL2" width="400" height="400" />
        <img src={home3} alt="WL3" width="400" height="400" />
        <img src={home4} alt="WL4" width="400" height="400" />
      </div>

      <br></br>

      <h2>Clientes y Aliados</h2>
      {window.screen.width < 768 ? (
        <ImageGallery
          items={imagesMain}
          infinite={state.infinite}
          showFullscreenButton={
            state.showFullscreenButton && state.showGalleryFullscreenButton
          }
        />
      ) : (
        <Carousel
          className={"exampleCarousel1"}
          show={5}
          slide={3}
          swiping={true}
          transition={0.8}
          responsive={true}
          useArrowKeys={true}
        >
          <a
            target="_blank"
            href="https://www.cemexmexico.com/"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo1} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a target="_blank" href="https://www.aesmex.com/es" rel="noreferrer">
            <Highlight color="#EEEBDD">
              <img src={logo2} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a target="_blank" href="https://www.wwf.org.mx/" rel="noreferrer">
            <Highlight color="#EEEBDD">
              <img src={logo3} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href=" https://www.pronatura-ppy.org/"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo4} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://www.pronaturanoreste.org/"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo5} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a target="_blank" href="https://costasalvaje.org/" rel="noreferrer">
            <Highlight color="#EEEBDD">
              <img src={logo6} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="http://www.mastozoologiamexicana.com/"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo7} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a target="_blank" href="http://ovis.org.mx/" rel="noreferrer">
            <Highlight color="#EEEBDD">
              <img src={logo8} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://www.animalkarma.org.mx/"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo9} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a target="_blank" href="https://pmcarbono.org/pmc/" rel="noreferrer">
            <Highlight color="#EEEBDD">
              <img src={logo10} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://www.fondoparalapazinformes.org/"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo11} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://www.amigosdesiankaan.org/"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo12} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/campamento.tlachtli/"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo13} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/Thesantogrial"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo14} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/raulcastrou"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo15} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/rioverdesalvaje"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo16} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/queondarapaz"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo17} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a target="_blank" href="(https://www.ipn.mx/" rel="noreferrer">
            <Highlight color="#EEEBDD">
              <img src={logo18} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a target="_blank" href="https://www.colpos.mx/wb/" rel="noreferrer">
            <Highlight color="#EEEBDD">
              <img src={logo19} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a target="_blank" href="https://www.uam.mx/" rel="noreferrer">
            <Highlight color="#EEEBDD">
              <img src={logo20} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a target="_blank" href="https://www.uaeh.edu.mx/" rel="noreferrer">
            <Highlight color="#EEEBDD">
              <img src={logo21} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a target="_blank" href="http://www.uaslp.mx/" rel="noreferrer">
            <Highlight color="#EEEBDD">
              <img src={logo22} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a target="_blank" href="https://www.uaaan.edu.mx/" rel="noreferrer">
            <Highlight color="#EEEBDD">
              <img src={logo23} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="(https://www.mda.cinvestav.mx/"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo24} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/%C3%81rea-de-Protecci%C3%B3n-de-Flora-y-Fauna-Balaan-Kaax-335830039780583"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo25} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/Reserva-de-la-Bi%C3%B3sfera-Calakmul-129271177252969"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo26} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://simec.conanp.gob.mx/ficha.php?anp=157&=11"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo27} alt="logo1" width="200" />
            </Highlight>
          </a>

          <a
            target="_blank"
            href="https://simec.conanp.gob.mx/ficha.php?anp=157&=11"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo28} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://simec.conanp.gob.mx/ficha.php?anp=157&=11"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo29} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://simec.conanp.gob.mx/ficha.php?anp=157&=11"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo30} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://simec.conanp.gob.mx/ficha.php?anp=157&=11"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo31} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://simec.conanp.gob.mx/ficha.php?anp=157&=11"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo32} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://simec.conanp.gob.mx/ficha.php?anp=157&=11"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo33} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://simec.conanp.gob.mx/ficha.php?anp=157&=11"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo34} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://simec.conanp.gob.mx/ficha.php?anp=157&=11"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo35} alt="logo1" width="200" />
            </Highlight>
          </a>
          <a
            target="_blank"
            href="https://simec.conanp.gob.mx/ficha.php?anp=157&=11"
            rel="noreferrer"
          >
            <Highlight color="#EEEBDD">
              <img src={logo36} alt="logo1" width="200" />
            </Highlight>
          </a>
        </Carousel>
      )}
    </div>
  );
};

export default Main;
