import hashtag from "./img/Hashtag_WMM.png";
const Footer = () => {
  return (
    <div className="jumbotron" id="footerEdit">
      <div className="hashtag-wild">
        <img src={hashtag} alt="hashtag" />
      </div>
      <div id="contacto">
        <h3>CONTACTO</h3>
        <br />
        <br />
        <div className="info">
          <a href="mailto:contacto@wildlifemanagementmexico.com">
            contacto@wildlifemanagementmexico.com
          </a>
          <p>(+52) 444 121 4148 / (+52) 55 18 23 9731</p>
          <p>
            Monte Champaquí #240, Col. Lomas 3a sección, C.P. 78216, San Luis
            Potosí, S.L.P{" "}
          </p>
        </div>
        <div className="info-redes">
          <a
            href="https://www.facebook.com/wildlifemanagementmx"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/wildlifemanagementmx/?hl=es-la"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a href="mailto:contacto@wildlifemanagementmexico.com">
            <i className="fas fa-envelope"></i>
          </a>
          <a
            href="https://twitter.com/MxWildlife?s=20"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="40"
              width="40"
              viewBox="0 0 512 512"
            >
              <path
                opacity="1"
                fill="#4e3620"
                d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
              />
            </svg>
          </a>
        </div>
      </div>

      <div id="footterCopyright">
        <p>© Copyright 2022 – Wildlife Management Mexico A.C.</p>
      </div>
    </div>
  );
};
export default Footer;
