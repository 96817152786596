

const PageNotFound = () => {
    return (
        <div>
            <h2>404:</h2>
            <p>Pagina No Encontrada</p>
        </div>
    )
}
export default PageNotFound;