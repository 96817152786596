import noticias1 from "./img/Noticias_Venta-Cámaras.png";
import noticias2 from "./img/Noticias_Fototrampeo_APFF.JPG";
import noticias3 from "./img/Noticias_Proyecto_Tráfico.png";
import noticias from "./img/noticias.jpg";

const Noticias = () => {
  return (
    <div id="noticias">
      <div className="banner-nosotros">
        <img src={noticias} alt="nosotros" className="img-nosotros" />
      </div>
      <div className="noticias-content">
        <h2>NOTICIAS</h2>
        <div className="cards">
          <div className="card-noticias">
            <img src={noticias1} alt="noticias" />
            <div className="container">
              <h3>Venta de trampas-cámara y equipo</h3>
              <p>
                Si necesitas monitorear fauna silvestre a través de fototrampeo,
                nosotros podemos ayudarte a conseguir la mejor trampa-cámara que
                se ajuste a tus necesidades, tanto de logística como económicas,
                ya sean fotos tradicionales o panorámicas. Ofrecemos modelos de
                las principales marcas en el ramo como: 𝗕𝗿𝗼𝘄𝗻𝗶𝗻𝗴, 𝗕𝘂𝘀𝗵𝗻𝗲𝗹𝗹,
                𝗖𝘂𝗱𝗱𝗲𝗯𝗮𝗰𝗸, 𝗠𝗼𝘂𝗹𝘁𝗿𝗶𝗲, 𝗦𝘁𝗲𝗮𝗹𝘁𝗵𝗖𝗮𝗺, entre otras. Igualmente te
                podemos ofrecer otros materiales y equipos para el manejo y
                monitoreo de fauna silvestre. Acércate a nosotros, contáctanos
                por redes sociales o a nuestro e-mail de contacto{" "}
                <span className="correo-wild">
                  <a href="mailto:contacto@wildlifemanagementmexico.com">
                    contacto@wildlifemanagementmexico.com
                  </a>
                </span>
                . <br />¹ Envíos a través de las principales paqueterías (𝘋𝘏𝘓,
                𝘌𝘴𝘵𝘢𝘧𝘦𝘵𝘢, 𝘍𝘦𝘥𝘌𝘹).
                <br />² Podemos expedir facturas por las compras.
              </p>
            </div>
          </div>
          <div className="card-noticias">
            <img src={noticias2} alt="noticias" />
            <div className="container">
              <h3>Fototrampeo en la APFF Sierra de Álvarez</h3>
              <p>
                Desde hace varias temporadas hemos estado trabajando con grupos
                de monitores comunitarios de sitios aledaños a la{" "}
                <strong>APFF Sierra de Álvarez</strong> en San Luis Potosí. Con
                ayuda de la comunidad y de los técnicos de la CONANP, los
                esfuerzos de monitoreo se han mantenido, a través de estaciones
                trampas-cámara se sigue haciendo el monitoreo de fauna
                silvestre. En las imágenes que se han obtenido en las revisiones
                podemos observar a viejos conocidos, algunas de las especies más
                comúnmente registradas en la región, como el caso de diversos
                <strong>mesocarnívoros</strong> (zorritas, linces, zorrillos,
                etc.) y algunos <strong>ungulados</strong> (venados cola blanca
                y pecaríes de collar), así como ardillas y aves.
              </p>
            </div>
          </div>
          <div className="card-noticias">
            <img src={noticias3} alt="noticias" />
            <div className="container">
              <h3>Proyecto de tráfico y comercio ilegal de jaguar</h3>
              <p>
                Durante 2022 estuvimos colaborando en un proyecto a nivel
                nacional que tuvo por objetivo diagnosticar el aprovechamiento,
                tráfico y comercio ilegal de jaguar (Panthera onca) en México.
                Nosotros, junto a otros investigadores y expertos, sumamos y
                coordinamos esfuerzos en el noreste del país (San Luis Potosí,
                Tamaulipas, Nuevo León e Hidalgo). Este Proyecto fue dirigido
                por la{" "}
                <strong>Asociación Mexicana De Mastozoología AC (𝗔𝗠𝗠𝗔𝗖)</strong>{" "}
                en colaboración con el{" "}
                <strong>World Wildlife Fund Inc. (WWF México)</strong>. Uno de
                los propósitos fue recopilar, analizar y sintetizar información
                que coadyuve a la toma de decisiones para la conservación y
                protección de estos carismáticos felinos silvestres de México.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Noticias;
