import ImageGallery from "react-image-gallery";
import wl0 from "./img/wl0.JPG";
import ga2 from "./img/ga2.png";
import ga3 from "./img/ga3.JPG";
import ga7 from "./img/ga7.jpeg";
import ga4 from "./img/ga4.JPG";
import ga5 from "./img/ga5.JPG";
import ga6 from "./img/ga6.JPG";
import wl6 from "./img/wl6.jpg";
import wl7 from "./img/wl7.jpg";
import wl8 from "./img/wl8.JPG";
import ga9 from "./img/ga9.jpg";
import ga8 from "./img/ga8.png";
import ga10 from "./img/ga10.jpeg";
import tucan from "./img/tucan.JPG";
import jaguar from "./img/wlCirculo.JPG";
const state = {
  showIndex: false,
  showBullets: true,
  infinite: true,
  showThumbnails: true,
  showFullscreenButton: true,
  showGalleryFullscreenButton: true,
  showPlayButton: true,
  showGalleryPlayButton: true,
  showNav: true,
  isRTL: false,
  slideDuration: 450,
  slideInterval: 2000,
  slideOnThumbnailOver: false,
  thumbnailPosition: "bottom",
  showVideo: {},
  useWindowKeyDown: true,
};

const images = [
  {
    original: `${ga5}`,

    originalWidth: 400,
  },
  {
    original: `${ga6}`,

    originalWidth: 400,
  },
  {
    original: `${ga8}`,

    originalWidth: 400,
  },
  {
    original: `${jaguar}`,

    originalWidth: 400,
  },
  {
    original: `${ga9}`,

    originalWidth: 400,
  },
  {
    original: `${wl8}`,

    originalWidth: 400,
  },
  {
    original: `${tucan}`,

    originalWidth: 400,
  },
];

function Gallery() {
  if (window.screen.width < 768) {
    return (
      <div style={{ background: "#464F41" }}>
        <h1 className="TitleGallery">Galería</h1>
        <ImageGallery
          items={images}
          infinite={state.infinite}
          showFullscreenButton={
            state.showFullscreenButton && state.showGalleryFullscreenButton
          }
        />
      </div>
    );
  } else {
    return (
      <div className="DivGalleryMain">
        <h1 className="TitleGallery">Galería</h1>

        <div className="galeria-texto">
          <div className="container-gallery">
            <div className="cont-img">
              <img src={tucan} alt="wl1" />
            </div>
            <div className="cont-img">
              <img src={ga2} alt="ga6" />
            </div>
            <div className="cont-img">
              <img src={wl7} alt="jaguar" />
            </div>
            <div className="cont-img">
              <img src={ga3} alt="wl5" />
            </div>
            <div className="cont-img">
              <img src={ga4} alt="wl3" />
            </div>
            <div className="cont-img">
              <img src={ga5} alt="wl6" />
            </div>
            <div className="cont-img">
              <img src={ga6} alt="wl7" />
            </div>
            <div className="cont-img">
              <img src={wl0} alt="ga4" />
            </div>
            <div className="cont-img">
              <img src={ga7} alt="1l7" />
            </div>
            <div className="cont-img">
              <img src={ga8} alt="wl8" />
            </div>
            <div className="cont-img">
              <img src={ga9} alt="wl8" />
            </div>
            <div className="cont-img">
              <img src={ga10} alt="wl2" />
            </div>
            <div className="cont-img">
              <img src={wl6} alt="wl8" />
            </div>
            <div className="cont-img">
              <img src={jaguar} alt="wl8" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
